<template>
  <div v-s-click-outside="closeMenu" class="multi-select">
    <div
      ref="multiSelectInput"
      :class="['multi-select-input', { 'multi-select-input-open': isDropdownOpen }]"
      @click="toggleDropdown"
    >
      <template v-for="(item, index) in selected" :key="item.value">
        <slot
          name="selection"
          :item="item"
          :index="index"
          :props="getSelectionProps(item)"
        >
          <span class="default-selected-item-title">{{ item.title }}</span>
        </slot>
      </template>
    </div>
    <teleport :to="target">
      <div v-if="isDropdownOpen" ref="dropdownContent" class="multi-select-dropdown-content" :style="dropdownStyle">
        <div class="multi-select-dropdown-list">
          <template v-for="item in items" :key="item.value">
            <slot name="item" :props="getItemProps(item)" :item="item">
              <div class="default-dropdown-list-item" @click="selectItem(item)">
                {{ item.title }}
              </div>
            </slot>
          </template>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { toRaw } from 'vue'
import { debounce } from '@/utils/rate-limit-util'

/**
 * The BaseMultiSelect component can be used with the following two slots:
 * - selection: This slot is used to customize the selected items and is displayed on the input box.
 * - item: This slot is used to customize the dropdown list items.
 */
export default {
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    /**
      * The `items` prop is an array of objects.
      * Each object should have atleast the following properties:
      * - title (string): The title of the item to be displayed in the dropdown list.
      * - value (String): The value of the item.
    */
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isDropdownOpen: false,
      multiSelectWidth: 'auto',
      dropdownWidth: 'auto',
      dropdownStyle: {},
      target: 'body'
    }
  },
  computed: {
    selected () {
      return this.modelValue
    }
  },
  mounted () {
    this.setWidth()
    this.setTarget()

    this.onResize = debounce(this.setWidth, 250)
    this.onScroll = debounce(this.updateOnScroll, 250)

    window.addEventListener('resize', this.onResize)
    window.addEventListener('scroll', this.updateOnScroll)
  },
  beforeUnmount () {
    window.removeEventListener('click', this.closeMenuOnClickOutside)
    window.removeEventListener('resize', this.setWidth)
    window.removeEventListener('scroll', this.updateOnScroll)
  },
  methods: {
    /**
     * Sets the width of the dropdown and multi-select input.
     * The width is determined by the width of the component's root element.
     * The width is set when the component is mounted as well as when the window is resized.
     */
    setWidth () {
      this.dropdownWidth = this.$parent.$el.clientWidth ? `${this.$parent.$el.clientWidth}px` : '100%'
      this.multiSelectWidth = this.$parent.$el.clientWidth ? `${this.$parent.$el.clientWidth}px` : '100%'
    },
    /**
     * Adjusts the dropdown's position in accordance with the multi-select input element's position whenever a scroll event occurs.
     */
    async updateOnScroll () {
      if (this.isDropdownOpen) {
        await this.$nextTick()
        this.setDropdownPosition()
      }
    },
    async toggleDropdown () {
      if (this.isDropdownOpen) {
        this.isDropdownOpen = false
      } else {
        this.isDropdownOpen = true
        await this.$nextTick()
        this.setDropdownPosition()
      }
    },
    /**
     * Sets the position of the dropdown according to the multi-select input.
     * The dropdown is displayed above the input if there's not enough space below it,
     * otherwise, it's displayed below the input.
     */
    setDropdownPosition () {
      const multiSelectInputRect = this.$refs.multiSelectInput.getBoundingClientRect()
      const dropdownContent = this.$refs.dropdownContent.getBoundingClientRect()
      const dropdownHeight = dropdownContent.height
      const spaceBelow = window.innerHeight - multiSelectInputRect.bottom
      const spaceAbove = multiSelectInputRect.top

      if (spaceBelow < dropdownHeight && spaceAbove > dropdownHeight) {
        // Open dropdown above the multi-select input
        this.dropdownStyle = {
          top: `${multiSelectInputRect.top - dropdownHeight}px`,
          left: `${multiSelectInputRect.left}px`
        }
      } else {
        // Open dropdown below the multi-select input
        this.dropdownStyle = {
          top: `${multiSelectInputRect.bottom}px`,
          left: `${multiSelectInputRect.left}px`
        }
      }
    },
    getItemProps (item) {
      return {
        onClick: () => this.selectItem(item)
      }
    },
    getSelectionProps (item) {
      return {
        onClose: () => this.selectItem(item)
      }
    },
    setTarget () {
      this.target = document.getElementById('modal') ? '#modal' : 'body'
    },
    selectItem (item) {
      let currentlySelected = structuredClone(toRaw(this.selected))
      const exists = currentlySelected.some(selectedItem => selectedItem.value === item.value)
      if (exists) {
        currentlySelected = currentlySelected.filter(selectedItem => selectedItem.value !== item.value)
      } else {
        currentlySelected.push(item)
      }
      this.$emit('update:modelValue', currentlySelected)
    },
    closeMenu () {
      this.isDropdownOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_mixins.scss";

@include scrollbar-redesign;

.multi-select {
  margin-top: 8px;
}

.multi-select-input {
  display: inline-block;
  border-radius: 2px;
  background-color: white;
  position: relative;
  cursor: pointer;
  height: 38px;
  overflow: hidden;
  padding: 2px 5px 0px;
  width: v-bind(multiSelectWidth);
  border: solid 1px $neutral-light-grey;
}

.multi-select-input-open {
  border: solid 1px $primary-digital-teal-accent;
}

.default-selected-item-title {
  margin-right: 8px;
  margin-bottom: 2px;
  padding: 2px 12px;
  background-color: $neutral-light-grey;
  border-radius: 4px;
}

.default-dropdown-list-item {
  display: flex;
  align-items: center;
  padding: 4px 16px;
  cursor: pointer;
  color: $neutral-typography-dark;

  &:hover {
    background-color: $hover-bg-color;
  }
}

.multi-select-dropdown-content {
  z-index: 95;
  border-radius: 4px;
  position: fixed;
  background: $surface-color;
  border: solid 1px $neutral-light-grey;
  width: v-bind(dropdownWidth);

  @include box-shadow;
}

.multi-select-dropdown-list {
  overflow-y: auto;
  height: 250px;
}
</style>

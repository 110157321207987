<template>
  <div :style="{width: `${size}px`, height: `${size}px`}" class="progress-circle-container">
    <div class="progress-circle-text" :style="{ color: color }">
      <slot></slot>
    </div>
    <svg :viewBox="`0 0 ${radius * 2.3} ${radius * 2.3}`">
      <!-- Mask the background circle so the edges don't bleed through the top circle -->
      <mask id="mask">
        <circle cx="50%" cy="50%" r="20" fill="black" stroke="white" stroke-width="2" />
      </mask>
      <!-- Background circle -->
      <circle
        mask="url(#mask)"
        :r="radius"
        cx="50%"
        cy="50%"
        :stroke-width="width"
        :stroke="backgroundStrokeColor"
        fill="transparent"
      />
      <!-- Animated foreground circle -->
      <g :class="{ rotate: indeterminate }">
        <circle
          :r="radius"
          cx="50%"
          cy="50%"
          :stroke="color"
          :stroke-width="width"
          fill="transparent"
          :class="animationClass"
          :style="setCssVariables"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import cssVariables from '@/assets/scss/_variables.module.scss'

export default {
  props: {
    // The percentage of the circle to be filled, 0-100
    modelValue: {
      type: Number,
      required: false,
      default: 0
    },
    color: {
      type: String,
      required: false,
      default: cssVariables.primaryColor
    },
    // Sets the animation to an infinite loop to indicate loading
    indeterminate: {
      type: Boolean,
      required: false,
      default: false
    },
    // Scales the size of the circle
    size: {
      type: Number,
      required: false,
      default: 32
    },
    // The stroke width of the circle
    width: {
      type: Number,
      required: false,
      default: 4
    }
  },
  data () {
    return {
      radius: 20
    }
  },
  computed: {
    backgroundStrokeColor () {
      return cssVariables.lightGrey
    },
    circumference () {
      const circumference = 2 * Math.PI * this.radius
      return circumference
    },
    setCssVariables () {
      return {
        '--circumference': this.circumference,
        '--dash-offset': this.calculateStrokeDashOffset()
      }
    },
    animationClass () {
      if (this.indeterminate) {
        return ['indeterminate-progress-stroke']
      } else {
        return ['progress-stroke']
      }
    }
  },
  methods: {
    calculateStrokeDashOffset () {
      const strokeDiff = this.dataPercentage(this.modelValue) * this.circumference
      return this.circumference - strokeDiff
    },
    dataPercentage (dataVal) {
      return dataVal / 100
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-circle-container {
  position: relative;
  display: inline-block;
}

.progress-circle-text {
  position: absolute;
  transform-origin: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Open Sans Semi-Bold";
  font-size: 12px;
}

.rotate {
  animation: rotate 1.4s linear infinite;
  transform-origin: center;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.indeterminate-progress-stroke {
  animation: indeterminate-progress-stroke 2s ease-in-out infinite;
  stroke-dasharray: 25, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  transform-origin: center;
  transform: rotate(-90deg);
}

@keyframes indeterminate-progress-stroke {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0px;
  }

  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -124px;
  }
}

.progress-stroke {
  animation: complete-progress-stroke 2s ease-in-out;
  animation-fill-mode: forwards;
  stroke-dasharray: var(--circumference);
  transform-origin: center;
  transform: rotate(-90deg);
}

@keyframes complete-progress-stroke {
  from {
    stroke-dashoffset: var(--circumference);
  }

  to {
    stroke-dashoffset: var(--dash-offset);
  }
}
</style>
